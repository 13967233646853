import Vue from 'vue';
import axios from 'axios';
import router from '@/router';
import VueLoading from '../components/loading';
import { removeToken, getToken } from '@/util/cookie';
let errorDisplayed = false;
export default class Request {
  constructor(baseURL, baseURLs, headers) {
    //baseURLs兼容之前
    this.baseURL = baseURL || '';
    this.baseURLs = baseURLs || false;
    this.headers = headers || false;
    this.config = null;
    return this.init();
  }
  init() {
    this.instance = axios.create({
      baseURL: this.baseURL,
      withCredentials: true,
      headers: this.headers || {},
    });
    Vue.use(VueLoading);
    this.instance.interceptors.request.use(this.requestSuccess, this.requestError);
    this.instance.interceptors.response.use(this.responseSuccess, this.responseError);
    return this.instance;
  }

  requestSuccess = (config) => {
    this.config = config;
    let token = getToken() || '';
    config.headers['Authorization'] = `Bearer ${token}`;
    //BI左侧加载树结构的时候，去除loading
    if (config.url.indexOf('getStoreTree') > -1) {
      config.noLoading = true;
    }
    config.noLoading ? '' : Vue.loading();
    config.withCredentials = true;
    this.baseURLs &&
      this.baseURLs.forEach((item) => {
        if (config.url.indexOf(item.key) > -1) {
          config.baseURL = item.baseURL;
        }
      });
    if (config.methods === 'get' && !config.params) {
      config.params = config.data;
    }
    return config;
  };

  requestError = (error) => {
    return Promise.reject(error);
  };

  responseSuccess = (response) => {
    Vue.loading && Vue.loading.end();

    if (
      (response.data.msg == '没有登录' ||
        response.data.status == '401' ||
        response.data.code == '401') &&
      window.vm
    ) {
      removeToken();
      localStorage.clear();
      sessionStorage.clear();
      router.push(`/login`);
      throw new axios.Cancel('登录状态失效');
    }
    if (response.data.status != 1 && response.data.msg) {
      window.vm.$message.error(response.data.msg);
    }
    return response;
  };
  clearLoginInfo = () => {
    removeToken();
    localStorage.clear();
    sessionStorage.clear();
    router.push(`/login`);
    setTimeout(() => {
      errorDisplayed = false;
    }, 3000);
  };
  responseError = (error) => {
    Vue.loading && Vue.loading.end();

    if (error && error.response) {
      if (error.response.status === 401) {
        removeToken();
        localStorage.clear();
        sessionStorage.clear();
        router.push(`/login`);
      } else if ([500, 502, 503, 504, 505].indexOf(error.response.status) !== -1) {
        //埋点接口报错不处理，直接返回
        let passUrls = ['/wakanda/v3/user-log/log'];
        if (error.config && error.config.url && passUrls.includes(error.config.url)) {
          return;
        }
        let message = '网络错误，请重试';
        if (
          error.response &&
          error.response.data &&
          (error.response.data.message || error.response.data.msg)
        ) {
          message = error.response.data.message || error.response.data.msg;
        }
        if (!errorDisplayed) {
          window.vm.$message.error(message);
          errorDisplayed = true;
          this.clearLoginInfo();
        }
      } else if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1) {
        if (!errorDisplayed) {
          window.vm.$message.error('网络请求超时，请重试');
          errorDisplayed = true;
          this.clearLoginInfo();
        }
      }
    } else if (String(error) === 'Error: Network Error') {
      if (!errorDisplayed) {
        window.vm.$message.error('网络连接失败，请检查网络后重试');
        errorDisplayed = true;
        this.clearLoginInfo();
      }
    } else {
      if (!errorDisplayed) {
        errorDisplayed = true;
        this.clearLoginInfo();
      }
    }
    return Promise.reject(error);
  };
}
