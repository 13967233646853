const state = {
  store: [],
  tenant: {},
  userInfo: {},

  currentNav: {},
  tree_all_type: 0,
  // tree_all_type: 230,
  // isCollapse: '219px', // 菜单收起大小
  // isCollapseType: false, // 菜单收起状态,
  WhetherTree: false,
};
const mutations = {
  SET_STORE_INFO: (state, data) => {
    state.store = data;
  },
  SET_TENANT_INFO: (state, data) => {
    state.tenant = data;
  },
  SET_USERINFO_INFO: (state, data) => {
    state.userInfo = data;
  },
  SET_ROLE_INFO: (state, data) => {
    state.roles = data;
  },
  // 当前顶部菜单
  SET_CURRENT_NAV: (state, nav) => {
    state.currentNav = nav;
  },
  TREE_ALL: (state, nav) => {
    state.tree_all_type = nav;
  },
  isCollapse_type(state, nav) {
    state.isCollapse = nav;
  },
  isCollapseType_updata(state, nav) {
    state.isCollapseType = nav;
  },
  WhetherTree_updata(state, nav) {
    state.WhetherTree = nav;
  },
};
const actions = {
  clear({ commit }) {
    commit("SET_CURRENT_TENANT_CODE", "");
    commit("SET_CURRENT_USER", {});
  },
};
export default {
  state,
  mutations,
  actions,
};
