export default [
  // 加盟商管理列表页 /Volumes/code/webWork/insight360/src/pages/externalPersonnelManagement/allianceBusiness/list/index.vue
  {
    path: '/externalPersonnelManagement/allianceBusiness/list',
    name: 'externalPersonnelManagement-allianceBusiness-list',
    component: () => import('@/pages/externalPersonnelManagement/allianceBusiness/list/index.vue'),
    meta: {
      keepAlive: true,
      pageName: '加盟商管理',
    },
  },
  // 施工方管理
  {
    path: '/externalPersonnelManagement/constructorMng/constructor',
    name: 'constructorMng',
    component: () =>
      import('@/pages/externalPersonnelManagement/constructorMng/constructorList.vue'),
    meta: {
      keepAlive: true,
      pageName: '施工方管理',
    },
  },
  // 供应商管理
  {
    path: '/externalPersonnelManagement/constructorMng/supplier',
    name: 'supplier',
    component: () => import('@/pages/externalPersonnelManagement/constructorMng/supplierList.vue'),
    meta: {
      keepAlive: true,
      pageName: '供应商管理',
    },
  },
];
