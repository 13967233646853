// ExportDialogPlugin.js
import Vue from 'vue'
import ExportDialog from './ExportDialog.vue'

export default {
    install(Vue, options) {
        Vue.prototype.$showExportDialog = function () {
            const ExportDialogClass = Vue.extend(ExportDialog)
            const instance = new ExportDialogClass({
                parent: this // 这里我们将父Vue实例传递给新实例
            })
            instance.$mount() // 这将创建并挂载我们的ExportDialog组件
            document.body.appendChild(instance.$el)
            instance.visible() // 显示对话框
        }
    }
}
