<script>
import renderMap, { renderFormItem } from './help';
const renderDefault = (h, _vm) => {
  console.log(_vm);
  return h(
    'el-form',
    {
      ref: 'Form',
      props: {
        'label-width': _vm.labelWidth,
        labelPosition: _vm.labelPosition,
        model: _vm.formData,
        inline: _vm.inline,
        'label-suffix': ':',
        size: 'small',
      },
    },
    [
      ..._vm.cols
        .filter((col) => {
          if (col.hidden) _vm.$set(_vm.formData, [col.field], null); // 表单隐藏时，将数据清空
          return !col.hidden;
        })
        .map((col) => _vm.renderCell(h, col)),
      ...(_vm.$slots.default || []),
    ],
  );
};
export default {
  name: 'base-form',
  components: {},
  props: {
    cols: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => {},
    },
    labelWidth: {
      default: '150px',
      type: String,
    },
    labelPosition: {
      default: 'right',
      type: String,
    },
    itemTypeString: {
      default: false,
      type: Boolean,
    },
    inline: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  render(h) {
    return h('div', { class: 'baseForm' }, [renderDefault(h, this)]);
  },
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    renderCell(h, col) {
      if (this.itemTypeString) col.type = 'string';
      if (col.slot) {
        return h('div', this.$slots[col.slot]);
      }
      const { type, field } = col;
      const {
        formData,
        $scopedSlots: { [field]: slotsFun },
      } = this;
      const { [type]: renderCellFun } = renderMap;
      let cell = null,
        childs = [];
      cell = slotsFun ? slotsFun({ col }) : renderCellFun && renderCellFun(h, this, col, formData);
      childs.push(cell);
      if (['h1', 'collapse'].includes(type)) return cell;
      return renderFormItem(h, this, col, childs);
    },
    validate() {
      const validPromise = [];
      this.$refs.Form.validate((valid) => {
        validPromise.push(valid ? Promise.resolve(true) : Promise.reject(false));
      });
      return Promise.all(validPromise).catch((error) => {
        const errorDom = this.$el.querySelector('.el-form-item.is-error');
        errorDom && errorDom.scrollIntoView({ behavior: 'smooth' });
        return error;
      });
    },
    clearValidate(props = []) {
      const fields = props.length
        ? typeof props === 'string'
          ? this.fields.filter((field) => props === field.prop)
          : this.fields.filter((field) => props.indexOf(field.prop) > -1)
        : this.fields;
      fields.forEach((field) => {
        field.validateState = '';
        field.validateMessage = '';
        field.validateDisabled = false;
      });
    },
    async resetFields() {
      await this.$nextTick();
      this.$refs.Form.resetFields();
    },
  },
};
</script>
<style>
.divider-title {
  border-left: 2px solid #0055ff;
  font-weight: bold;
  padding: 2px 10px;
  margin-bottom: 20px;
  background: #fafcff;
}
.hide-upload-limit .el-upload {
  display: none;
}
</style>
