<!--
tab页面可传入customEvent事件和params参数使用，二级路由可直接调用传入title=>{label:'name'}
-->
<template>
<div>
  <div class="tab-container">
    <div class="tab-item-box first-tab-inner">
      <div class="tab-title">
        <div class='title' v-for="(item,index) in title" :key="index">
          <!-- 二级页面显示 -->
          <span v-if="!item.params" :class="title.length-1===index ? 'title-text':'title-text-active'" @click="toUrl(index)">{{item.label}}</span>
          <!-- tab页面显示 -->
          <span v-if="item.params" :class="title.length-1===index ? 'title-text':'title-text-active'" @click="customEvent(item.params)">{{item.label}}</span><span class="jiange">{{title.length-1===index ? '' : '/'}}</span>
        </div>
      </div>
    </div>
  </div>
  <ul v-if="tabs.length" class="tab-content radius-box">
    <li v-for="(item,index) in tabs"
      :key="index"
      :style="item.disabled ? 'cursor:not-allowed;': ''"
      :class="item.status==='active' ? 'tab-item-active' : item.status==='disable' ? 'tab-item-disable' : 'tab-item'"
      @click="toPage(item)">
        {{item.label}}
  </li>
  </ul>
</div>
</template>

<script>
export default {
  props: {
    title: {
      type: Array,
      default() {
        return [];
      }
    },
    tabs: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      currentTab: 0,
      toUrlArr: []
    };
  },
  mounted() {
    let pathArr = this.$route.path.split('/')
    let length = pathArr.length
    let toUrlArr = []
    for(let i=length-1;i-->0;){
      toUrlArr[i] = pathArr.join("/")
      pathArr.pop()
    }
    this.toUrlArr = toUrlArr
  },
  methods: {
    toPage(data) {
      if(!data.url || data.disabled) return
      this.$emit("change", data)
      this.$router.push(data.url)
      let tabs = JSON.parse(JSON.stringify(this.tabs))
      tabs.forEach(item => {
        if(item.url === data.url){
          item.status = "active"
        }else{
          item.status = ""
        }
      })
      this.$emit("update:tabs", tabs)
    },
    toUrl(index){
      // 优先跳转自带链接
      const item = this.title[index];
      if(item && item.url) {
        this.$router.push(item.url);
        return ;
      }
      if(this.title.length-1 == index) return
      let path = this.toUrlArr[index]
      this.$router.push({
        path
      });
    },
    customEvent(params){
      this.$emit("customEvent",params)
    }
  },
};
</script>

<style lang="scss" scoped>
.tab-container{
  .title{
    display: inline-block;
    .title-text-active{
      color: #8c8c8c;
      cursor: pointer;
      &:hover{
        color: #ff6b00;
      }
    }
  }
  .jiange{
    margin: 0 5px;
    color: #8c8c8c;
  }
}
.tab-title{
  padding: 24px 0 20px;
  color: rgba(0, 0, 0, .9);
  font-size: 18px;
  font-weight: 600;
}


//tabs样式
.tab-content {
  padding: 0 8px;
  background: white;
  border-bottom: 1px solid #edebe9;
  display: flex;
  font-size: 14px;
  .tab-item,
  .tab-item-active,
  .tab-item-disable {
    list-style: none;
    padding: 24px 20px 14px;

    font-family: PingFangSC-Semibold, PingFang SC;
    color: #393C42;
    line-height: 20px;

    text-align: center;
    position: relative;
    cursor: pointer;
  }

  .tab-item-active {
    font-weight: 600;
  }
  .tab-item-active::after {
    position: absolute;
    content: "";
    display: block;
    width: 56px;
    height: 2px;
    background: #ff6b00;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .tab-item-disable {
    color: #999999;
  }
}
</style>