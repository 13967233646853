<!-- 面包屑 -->
<template>
  <div id="mz-antd-bread-wrap">
    <a-breadcrumb id="mz-antd-breadcrumb" :routes="routes">
      <template slot="itemRender" slot-scope="{ route, params, routes, paths }">
        <!-- <span v-if="routes.indexOf(route) === routes.length - 1" class="route-name"> -->
        <span v-if="routes.indexOf(route) === routes.length - 1" class="route-name">
          {{ route.name }}
        </span>
        <!-- paths.join('/') -->
        <router-link v-else :to="{
          path: route.path,
          query: route.query,
        }" class="route-name">
          {{ route.name }}
        </router-link>
      </template>
    </a-breadcrumb>
    <slot name="rightRender"></slot>
  </div>
</template>

<script>
export default {
  props: {
    routes: {
      type: Array,
      default() {
        return [{ path: '', name: '' }];
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
#mz-antd-bread-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  #mz-antd-breadcrumb {
    height: 48px;
    line-height: 48px;
    .route-name {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
    }
    & > span:last-child {
      font-size: 18px !important;
      font-family: PingFangSC-Medium, PingFang SC !important;
      font-weight: 500;
      color: #262626;
    }
  }
}
</style>
