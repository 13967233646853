import Vue from 'vue';
import Vuex from 'vuex';
import codeList from './modules/codeList';
import tenant from './modules/tenant';
import menu from './modules/menu';
import BIV2 from './modules/BI-v2';
import getters from './getters';
import workflow from './modules/workflow';
import { ref } from 'vue';

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    codeList,
    tenant,
    menu,
    BIV2,
    workflow,
  },
  getters,
});

// usestore
export function useStore() {
  return { storeRef: ref(store), store };
}

export default store;
