const userManage = () =>
  import(/* webpackChunkName: "autho" */ '../../pages/autho/userManage/index.vue');
const roleManage = () =>
  import(/* webpackChunkName: "autho" */ '../../pages/autho/roleManage/index.vue');
const meunManage = () =>
  import(/* webpackChunkName: "autho" */ '../../pages/autho/meunManage/index.vue');
const managerMapping = () =>
  import(/* webpackChunkName: "autho" */ '../../pages/autho/managerMapping/index.vue');
export default [
  {
    path: '/autho/managerMapping',
    name: 'managerMapping',
    component: managerMapping,
    meta: {
      pageName: '开发经理映射',
    },
  },
  {
    path: '/autho/userManage',
    name: 'userManage',
    component: userManage,
    meta: {
      keepAlive: true,
      pageName: '用户管理',
    },
  },
  {
    path: '/autho/roleManage',
    name: 'roleManage',
    component: roleManage,
    meta: {
      keepAlive: true,
      pageName: '角色管理',
    },
  },
  {
    path: '/autho/meunManage',
    name: 'meunManage',
    component: meunManage,
    meta: {
      pageName: '菜单管理',
    },
  },
];
