// 招商签约
const investmentSigning = () =>
  import(/* webpackChunkName: "investmentSigning" */ '@/investment/pages/investment/signing/index');
// 招商政策
const investmentPolicies = () =>
  import(
    /* webpackChunkName: "investmentPolicies" */ '@/investment/pages/investment/policies/index'
  );
// 导出下载页
const download = () =>
  import(
    /* webpackChunkName: "download" */ '@/investment/pages/investment/download/index'
  );
export default [
  {
    path: '/investment/signing',
    name: 'investmentSigning',
    component: investmentSigning,
    meta: {
      keepAlive: true,
      pageName: '招商签约',
    },
  },
  {
    path: '/investment/policies',
    name: 'investmentPolicies',
    component: investmentPolicies,
    meta: {
      keepAlive: true,
      pageName: '招商政策',
    },
  },
  {
    path: '/investment/download',
    name: 'download',
    component: download,
    meta: {
      pageName: '导出列表',
    },
  },
];
