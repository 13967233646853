import request from '@/libs/API_common.js';

const baseUrl = `/wakanda/v3/tree`;

// 门店树 --- 标签
export const storeTag = (params) =>
  request({
    url: `${baseUrl}/tags/store`,
    method: 'GET',
    params,
  });

export const storeTree = (params) =>
  request({
    url: `${baseUrl}/store`,
    method: 'GET',
    params,
  });

export const goodsTag = (params) =>
  request({
    url: `${baseUrl}/tags/goods`,
    method: 'GET',
    params,
  });

export const goodsTree = (params) =>
  request({
    url: `${baseUrl}/goods`,
    method: 'GET',
    params,
  });
