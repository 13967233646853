import Cookies from 'js-cookie';
const name = 'userName';
const sit = 'sit';
const tokenKey = 'ykd_token';

// let domain = window.location.hostname.indexOf('.bjddlm.com') > -1 ? '.bjddlm.com' : '';
// let oldDomain = window.location.hostname.indexOf('bjddlm.com') > -1 ? 'bjddlm.com' : '';

export const getName = () => Cookies.get(name);
export const getSit = () => Cookies.get(sit);
export const getToken = () => {
  return Cookies.get(tokenKey);
};
export const removeToken = () => {
  Cookies.remove(tokenKey);
  Cookies.remove(tokenKey, { domain: '.ynzyq.net' });
  Cookies.remove(tokenKey, { domain: '.bjddlm.com' });
  Cookies.remove(tokenKey, { domain: 'bjddlm.com' });
};
export const setToken = (Token) => Cookies.set(tokenKey, Token, { expires: 30 });
