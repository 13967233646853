<template>
  <div class="all-product">
    <a-input ref="userNameInput" class="top-input" v-model="searchVal" placeholder="请输入关键字" @change="onSearch">
      <!-- <a-icon slot="prefix" type="search" /> -->
      <MzIcon class="search-icon" style="color: rgb(170, 180, 200)" slot="prefix" type="icon-sousuo" />
      
    </a-input>

    <div class="scroll-container">
      <div class="resent-visitor">
        <div class="title">最近使用</div>
        <div  class="inline-block">
          <template v-if="recentMenuList && recentMenuList.length">
            <div v-for="(item,index) in recentMenuList" :key="index" class="item" @click="toResent(item)">
              {{item.menuName}}
            </div>
          </template>
          <div v-else style="padding-left: 5px;">暂无最近访问</div>
        </div>
      </div>
      <div v-if="searchVal" class="result">
        共找到 
        <span style="color:#ff6b00">{{searchNum}} </span>
        个与
        <span style="color:#ff6b00">{{searchVal}} </span>
        相关的产品。
      </div>
      <div class="module-container">
        <div v-for="(item,index) in moduleList" class="block">
          <div class="title">{{item.name}}</div>
          <div
            v-for="(menu,index) in item.menuList"
            :key="index"
            class="item"
            @click="toPage(menu)"
            :class="[(menu.isDisabled || !menu.isAuthed) ? 'isDisabled':'']">
              {{menu.menuName}}
              <!-- <span v-if="!menu.isAuthed" class="tag">未开通</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { setRecentMenuLis } from '@/storage/menu'

  export default {
    data() {
      return {
        recentMenuList: [],
        moduleList: [],
        copyMenuInfo: {},
        searchVal:'',
        searchNum: 0,
      }
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      menuInfo: {
        type: Array,
        default: []
      },
    },
    computed: {
      ...mapState({
        menus: state => state.menu.menus,
        modules: state => state.menu.modules,
      }),
    },
    methods: {
      onSearch(e) {
        const temp = JSON.parse(JSON.stringify(this.copyMenuInfo))
        this.searchNum = 0
        this.moduleList = temp.filter(item => {
          item.menuList = item.menuList.filter(menu => {
            if(menu.menuName.indexOf(e.target.value) !== -1) {
              this.searchNum++
              return true
            }
          })
          return item.menuList.length
        })
      },
      handleSearch(val) {
        this.moduleList.menuList = this.moduleList.menuList.filter(item => {
           return item.menuName.indexOf(val) !== -1
        })
      },
      toResent(item) {
        this.$router.push({
          path: item.targetUrl
        })
        this.$emit('handleClick')
        this.$emit('close')
      },
      toPage(menusList) {
        if(!menusList.isAuthed || menusList.isDisabled) return
        
        this.$router.push({
          path: menusList.copyTargetUrl
        })
        this.$emit('handleClick')
        this.$emit('close')
      },
    },
    watch: {
      menus: {
        handler(newValue, oldValue) {
          if(newValue && JSON.parse(JSON.stringify(newValue)) !== '{}') {
            this.copyMenuInfo = this.moduleList = JSON.parse(JSON.stringify(this.menuInfo))
          }
        },
        deep: true,
        immediate:true
      },
      visible: {
        handler(newVal) {
          if(newVal) {
            const recentMenuList = localStorage.getItem('recentMenuList');
            if(recentMenuList) {
              this.recentMenuList = JSON.parse(recentMenuList)
            }
          }
        },
        immediate:true
      }
    },
  }
</script>

<style lang="scss" scoped>
.all-product {
  width: 100%;
  position: relative;
  padding-right: 36px;
  // max-height: calc(100vh - 100px);
  overflow: hidden;
  /deep/ .ant-input {
    height: 44px;
    border-radius: 8px;
  }

  .top-input {
    margin-bottom: 28px;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    // font-family: PingFangSC-Medium, PingFang SC;
    margin-bottom: 16px;
    padding-left: 5px;
  }
  
  .scroll-container {
    overflow: auto;
    max-height: calc(100vh - 170px);

  }

  .resent-visitor {
    .inline-block {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 40px;

      .item {
        width: 29%;
        margin-right: 28px;
      }
    }
  }

  .result {
    margin-bottom: 20px;
    color: #c0c6cc;
    font-size: 13px;
  }

  .item {
    font-size: 14px;
    color: #666;
    line-height: 24px;
    padding: 4px;
    cursor: pointer;

    &:hover {
      background: #ECF8FF;
      color: #ff6b00
    }
  }

  .module-container {
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .block {
      margin-bottom: 25px;
      width: 29%;
      margin-right: 28px;

      
      .active {
        // background: #ECF8FF;
        color: #ff6b00
      }

      .isDisabled {
        color: rgba(0, 0, 0, 0.25);
        cursor: not-allowed
      }
      .tag {
        margin-left: 5px;
        font-size: 12px;
        border: 1px solid #DCDFE6;
        padding:2px 4px;
      }
    }
  }

}
</style>