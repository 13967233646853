<template>
  <div :class="className">
  </div>
</template>


<script>
//变动观察器：子节点的增减、属性的变动、文本内容的变动可以得到通知
const MutationObserver =
    window.MutationObserver ||
    window.WebKitMutationObserver ||
    window.MozMutationObserver;
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    zIndex: {
      type: Number,
      default: 10000,
    },
  },
  data() {
    return {
      styleStr: "",//样式字符串
      mObserve: null, //变动观察器
      className: "mz-water-mark", //最外层div样式class
    };
  },
  mounted() {
    this.styleStr = `
        position:fixed;
        top:0;
        left:0;
        bottom:0;
        right:0;
        width:100%;
        height:100%;
        z-index:${this.zIndex};
        pointer-events:none;
        background-repeat:repeat;`
    this.showCanvas()
  },
  beforeDestroy() {
    if (this.mObserve) {
      this.mObserve.disconnect();
      this.mObserve.takeRecords();
      this.mObserve = null;
    }
    //移除水印
    // const queryDiv = document.querySelector('.' + this.className);
    // if(queryDiv){
    //   queryDiv.remove();
    // }
  },
  methods: {
    //绘制水印图
    showCanvas(){
      let width = "300px",
          container = document.body,
          height = '200px',
          textAlign = 'center',
          textBaseline = 'middle',
          font = "400 16px PingFangSC-Regular, PingFang SC ",
          fillStyle = 'rgba(184, 184, 184, 0.2)',
          content = '',
          rotate = '40';
      //姓名
      let userInfo = this.$store.state.tenant.userInfo;
      let userName = userInfo.realName || "";
      //手机号或者门店编号后四位
      let phone = userInfo.phone || userInfo.storeCode || "";
      phone += "";
      let cLength = phone.length;
      if(cLength > 5){
        phone = phone.substring(cLength - 4, cLength);
      }
      content = `${ userName }${phone}`;
      const canvas = document.createElement('canvas');

      canvas.setAttribute('width', width);
      canvas.setAttribute('height', height);
      const ctx = canvas.getContext("2d");

      ctx.textAlign = textAlign;
      ctx.textBaseline = textBaseline;
      ctx.font = font;
      ctx.fillStyle = fillStyle;
      ctx.rotate(Math.PI / 180 * rotate);
      ctx.fillText(content, parseFloat(width) / 3, parseFloat(height) / 3);

      const base64Url = canvas.toDataURL();
      this.styleStr += `background-image:url('${base64Url}');`
      const queryDiv = document.querySelector('.' + this.className);

      const watermarkDiv = queryDiv || document.createElement("div");

      watermarkDiv.setAttribute('style', this.styleStr);
      watermarkDiv.classList.add(this.className);

      if (!queryDiv) {
        container.insertBefore(watermarkDiv, container.firstChild);
      }
      //利用变动观察器监测节点变动，以防删除水印
      // if(MutationObserver){
      //   this.mObserve = new MutationObserver(this.handleChange);
      //   this.mObserve.observe(container, {
      //     attributes: true,
      //     subtree: true,
      //     childList: true
      //   });
      // }
    },
    //处理节点变动
    handleChange(){
      //获取水印节点
      const queryDiv = document.querySelector('.' + this.className);
      // 只在元素变动才重新调用
      if ((queryDiv && queryDiv.getAttribute('style') !== this.styleStr) || !queryDiv) {
        // 避免一直触发
        this.mObserve.disconnect();
        this.mObserve = null;
        this.showCanvas();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>