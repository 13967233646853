import request from '@/libs/API_common.js';
//获取菜单列表
export const getMenuList = () =>
    request({
        url: '/om-auth/api/auth/newResource/tree_list',
        method: 'get',
    });

//新增菜单
export const addMenu = (data) =>
    request({
        url: '/om-auth/api/auth/newResource/add',
        method: 'post',
        data,
    });

//修改菜单
export const updateMenu = (data) =>
    request({
        url: '/om-auth/api/auth/newResource/update',
        method: 'post',
        data,
    });

//禁用菜单
export const enableMenu = (data) =>
    request({
        url: '/om-auth/api/auth/newResource/enable',
        method: 'post',
        data,
    });

//删除菜单
export const deleteMenu = (id) =>
    request({
        url: '/om-auth/api/auth/newResource/delete/' + id,
        method: 'delete',
    });

//获取登录人菜单信息
export const getUserMenu = () =>
    request({
        url: '/om-auth/api/auth/newResource/myResource',
        method: 'get',
        noLoading: true,
    });
