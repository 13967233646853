import {
  storeTag,
  storeTree,
  goodsTag,
  goodsTree,
} from '@/api/BI-v2/filterTree/index'
import { defaultStoreGroupData } from '../../constanst/storeGroup'
import _ from 'lodash'

const handleCheckAll = (data, arr, parentName) => {
  data.map((item) => {
    if (item.children) {
      handleCheckAll(item.children, arr, item.name)
    } else {
      item.isLeaf = true
      item.parentName = parentName
    }
    item.key = (item.value || item.name) + '+_' + (item.parentName || '')
    item.value && arr.push(item.key)
  })
  return arr
}

const splitData = (data) => {
  return data.map((item) => {
    if (!item.value) {
      return {
        name: item.name,
        value: item.value,
        key: item.name + '+_',
        children: [],
      }
    }
  })
}

const state = {
  requestLengh: 0,
  treeListData: [
    {
      key: 'store',
      isLoad: false, //是否加载完
      selectIds: [],
      treeDataBackUp: [],
      treeData: [],
      tagList: [],
      activeTag: {},
      totalDataLength: 0,
      isLoadData: true,
      tagApi: storeTag,
      treeApi: storeTree,
    },
    {
      key: 'goods',
      isLoad: false, //是否加载完
      selectIds: [],
      selectIdsAll: [],
      treeDataBackUp: [],
      treeData: [],
      tagList: [],
      activeTag: {},
      totalDataLength: 0,
      isLoadData: true,
      tagApi: goodsTag,
      treeApi: goodsTree,
    },
  ],
  isDownLoadingData: {
    tipWords: '数据下载中',
    status: false,
  },
  // 门店分组
  storeGroupInfo: defaultStoreGroupData,
}

const getters = {
  isfinishLoad(state) {
    return state.treeListData.every((v) => v.isLoad)
  },
}

const mutations = {
  setTag(state, payload) {
    let { index, data } = payload
    state.treeListData[index].tagList = data
    state.treeListData[index].activeTag = data[0]
  },
  setTree(state, payload) {
    let { index, data } = payload
    state.treeListData[index].treeData = data
  },
  setTreeBackUp(state, payload) {
    let { index, data } = payload
    state.treeListData[index].treeDataBackUp = data
  },
  setTotalLength(state, payload) {
    let { index, totalLength } = payload
    state.treeListData[index].totalDataLength = totalLength
  },
  setSelectIds(state, payload) {
    let { index, data } = payload
    state.treeListData[index].selectIds = data
  },
  spliceSelectIds(state, payload) {
    let { index, spliceIndex } = payload
    console.log('spliceIndex--->', spliceIndex)
    const selectIds = _.cloneDeep(state.treeListData[index].selectIds)
    selectIds.splice(spliceIndex, 1)
    console.log('selectIds===>', selectIds)
    state.treeListData[index].selectIds = Array.from(new Set(selectIds)).filter(
      (item) => !new RegExp('[\u4e00-\u9fa5]').test(item.split('+_')[0])
    )
  },
  addSelectIds(state, payload) {
    let { index, data } = payload
    console.log('addSelectIds--->', data)
    state.treeListData[index].selectIds.push(data)
  },
  // 异步加载选中的全部数据
  setSelectAllIds(state, payload) {
    let { index, data } = payload
    state.treeListData[index].selectIdsAll = data
  },
  spliceSelectAllIds(state, payload) {
    let { index, spliceIndex } = payload
    // console.log('spliceSelectAllIds--->', spliceIndex)
    const selectIdsAll = _.cloneDeep(state.treeListData[index].selectIdsAll)
    selectIdsAll.splice(spliceIndex, 1)
    // state.treeListData[index].selectIdsAll.splice(spliceIndex, 1)
    state.treeListData[index].selectIdsAll = Array.from(
      new Set(selectIdsAll)
    ).filter((item) => !new RegExp('[\u4e00-\u9fa5]').test(item.split('+_')[0]))
  },
  addSelectAllIds(state, payload) {
    let { index, data } = payload
    state.treeListData[index].selectIdsAll.push(data)
  },
  setLoad(state, payload) {
    let { index, isLoad } = payload
    state.treeListData[index].isLoad = isLoad
  },
  // 是否下载中
  setDownLoadDataStatus(state, payload) {
    state.isDownLoadingData = payload
  },
  // 设置门店分组
  setStoreGroupInfo(state, payload) {
    const { data } = payload
    state.storeGroupInfo = JSON.parse(JSON.stringify(data))
  },
}
const actions = {
  getListData({ commit, dispatch, state }) {
    const treeListData = state.treeListData
    // 鱼你私有部署的里边没有bi的需求， 这个接口影响页面报错，所以注释掉---2023-12-01
    // treeListData.forEach((treeItem, index) => {
    //   treeItem
    //     .tagApi()
    //     .then((res) => {
    //       if (res.data.code === 0) {
    //         commit('setTag', { data: res.data.data, index })
    //       }
    //     })
    //     .then(() => {
    //       dispatch('getTree', { treeItem, index })
    //     })
    //     .catch((e) => {
    //       console.log('e===>', e)
    //     })
    // })
  },
  getTree({ commit }, { treeItem, index }) {
    // console.log('commit', commit)
    // console.log('treeItem', treeItem)
    // console.log('index', index)

    return treeItem
      .treeApi({ tag: treeItem.activeTag.value })
      .then((res) => {
        if (res.data.code === 0) {
          let temp = handleCheckAll(res.data.data, [])
          // console.log('temp---->', temp)
          // console.log('temp.length--->', temp.length)
          let treeNodes = res.data.data.map((item) => `${item.name}+_`)
          commit('setTreeBackUp', {
            data: JSON.parse(JSON.stringify(res.data.data)),
            index,
          })
          // // 判断是否需要异步加载
          if (treeItem.isLoadData) {
            // let treeData = splitData(res.data.data)
            let treeData = res.data.data
            commit('setTree', { data: treeData, index })
            commit('setSelectIds', {
              data: temp,
              // data: treeData.map((item) => item.key),
              // data: Array.from(new Set(temp)).concat(treeNodes),
              index,
            })
            // commit('setTotalLength', { totalLength: treeData.length, index })
            commit('setTotalLength', {
              totalLength: temp.length,
              index,
            })
            commit('setSelectAllIds', {
              data: temp,
              // data: Array.from(new Set(temp)).concat(treeNodes),
              index,
            })
          } else {
            commit('setTree', { data: res.data.data, index })
            commit('setSelectIds', {
              data: temp,
              // data: Array.from(new Set(temp)).concat(treeNodes),
              index,
            })
            // commit('setTotalLength', { totalLength: temp.length, index })
            commit('setTotalLength', {
              totalLength: temp.length,
              index,
            })
            commit('setSelectAllIds', {
              data: temp,
              // data: Array.from(new Set(temp)).concat(treeNodes),
              index,
            })
          }
          commit('setLoad', { isLoad: true, index })
        }
      })
      .catch((e) => {
        console.log('e===>', e)
      })
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
