import Request from './request';

// 根路径
const baseURLs = [
  {
    key: 'dataone-production',
    baseURL: '', //兼容之前文件
  },

  {
    key: '/coupon/api/',
    baseURL: '',
  },

  {
    key: '/api/user/',
    baseURL: '',
  },

  {
    key: '/store-inspect',
    baseURL: '',
  },

  {
    key: '/om-auth',
    baseURL: '',
  },
  {
    key: '/api/auth/v3',
    baseURL: '',
  },
  {
    key: '/api/wx-open-platform',
    baseURL: '',
  },
  
  {
    key: '/api/flow',
    baseURL: '',
  },
];
const baseURL = '/dataone-api/';
let instance = new Request(baseURL, baseURLs);
export default instance;
