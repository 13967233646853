module.exports = {
    data() {
        return {}
    },
    methods: {
        guid(len = 32, firstU = true, radix = null) {
            let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
            let uuid = []
            radix = radix || chars.length

            if (len) {
                // 如果指定uuid长度,只是取随机的字符,0|x为位运算,能去掉x的小数位,返回整数位
                for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)]
            } else {
                let r
                // rfc4122标准要求返回的uuid中,某些位为固定的字符
                uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
                uuid[14] = '4'

                for (let i = 0; i < 36; i++) {
                    if (!uuid[i]) {
                        r = 0 | (Math.random() * 16)
                        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r]
                    }
                }
            }
            // 移除第一个字符,并用u替代,因为第一个字符为数值时,该guuid不能用作id或者class
            if (firstU) {
                uuid.shift()
                return 'u' + uuid.join('')
            } else {
                return uuid.join('')
            }
        },
        addFn(arg1, arg2) {
            // 解决加法精度丢失
            var r1, r2, m
            try {
                r1 = arg1.toString().split('.')[1].length
            } catch (e) {
                r1 = 0
            }
            try {
                r2 = arg2.toString().split('.')[1].length
            } catch (e) {
                r2 = 0
            }
            m = Math.pow(10, Math.max(r1, r2))
            return (arg1 * m + arg2 * m) / m
        },
        subFn(arg2, arg1) {
            // 解决减法法精度丢失
            var r1, r2, m, n
            try {
                r1 = arg1.toString().split('.')[1].length
            } catch (e) {
                r1 = 0
            }
            try {
                r2 = arg2.toString().split('.')[1].length
            } catch (e) {
                r2 = 0
            }
            m = Math.pow(10, Math.max(r1, r2))
            //lastmodifybydeeka
            //动态控制精度长度
            n = r1 >= r2 ? r1 : r2
            return ((arg2 * m - arg1 * m) / m).toFixed(n)
        }
    }
}
