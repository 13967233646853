import request from '@/libs/API_common.js';

const baseUrl = '/api/auth/v3';
//获取租户和用户信息
export const getUserInfo = (data) =>
  request({
    url: `${baseUrl}/user/info`,
    method: 'POST',
    data,
  });

//获取菜单
export const getMenu = (data) =>
  request({
    url: `${baseUrl}/menu/list`,
    method: 'POST',
    params: data,
  }).then((res) => {
    return res;
  });

//退出登录
export const logout = (data) =>
  request({
    url: '/om-auth/api/auth/logout',
    method: 'GET',
    params: data,
  });
//查询允许编辑流程模版的用户手机号
export const featchTmpAuthPhoneList = (data) =>
  request({
    url: '/om-auth/api/auth/tmp/tmpAuthPhoneList',
    method: 'GET',
    params: data,
  });
export const queryFranceOld = (data) =>
  request({
    url: '/om-auth/api/auth/franchisee/queryFranceOld',
    method: 'GET',
    params: data,
  });
