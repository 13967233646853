// 数据字典枚举
export default {
    shop_manager_account_status: [
        {
            value: '1',
            label: '冻结账号'
        },
        {
            value: '0',
            label: '正常账号'
        }
    ]
}
