//明之门店应用配置
const mzmdSet = () => import('@/pages/BI-v2/mzmdSet/index.vue');
export default [
  {
    path: '/BIV3/mzmdSet',
    component: mzmdSet,
    meta: {
      title: '明之门店应用配置',
      isBIV3: true,
      pageName: '底部菜单',
    },
  },
];
