import store from '../store';
import { getStoreBuildTodoCount, getNodeListCount } from '@/api/workflow/index';
import { computed } from 'vue';

export const getStoreBuildTodoNum = async () => {
  const { data = 0 } = await getStoreBuildTodoCount();
  store.commit('setStoreBuildTodoCount', data);
};
export const getStoreBuildNodeMonitorErrorCount = async () => {
  //  TODO: 暂时关闭显示异常节点的功能，该接口性能差，不适合频繁调用
  const result = await getNodeListCount({ pageNo: 1, pageSize: 20, suggest: '', isError: 2 });
  store.commit('setStoreBuildNodeMonitorErrorCount', result.data);
};
export const hasTmpAuth = computed(() => {
  return store.state.menu.tmpAuthPhoneList.includes(store.state.tenant.userInfo?.phone);
});
export const hasExportDataAuth = computed(() => {
  return [
    '13631278451',
    '13631278452',
    '15901418041',
    '18910047016',
    '15110042763',
    '15128248410',
  ].includes(store.state.tenant.userInfo?.phone);
});
export const hasRefundOrderAndMoney = computed(() => {
  return ['13631278451', '13631278452', '15901442367'].includes(store.state.tenant.userInfo?.phone);
});
