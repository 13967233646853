<template>
    <div class="page-wrap">
        <el-dialog
            title="导出"
            :visible.sync="dialogVisible"
            width="30%"
            :modal-append-to-body="false"
            append-to-body
        >
            <span style="font-size: 20px">
                <i
                    class="el-icon-document"
                    style="font-size: 24px; margin-right: 5px; color: skyblue"
                ></i>
                导出中....
            </span>
            <span slot="footer" class="dialog-footer" @click="toDownload" style="cursor: pointer">
                请前往下载清单查看
                <i
                    class="el-icon-right"
                    style="font-size: 24px; vertical-align: -12%; color: orange"
                ></i>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    // props:{
    //     dialogVisible:{
    //         type:Boolean,
    //         defalut:false
    //     }
    // },
    data() {
        return {
            dialogVisible: false
        }
    },
    watch: {},
    computed: {},
    created() {},
    deactivated() {
        this.dialogVisible = false
    },
    methods: {
        visible() {
            this.dialogVisible = true
        },
        closeDetaila() {
            this.$emit('closeDetaila', false)
        },
        toDownload() {
            this.closeDetaila()
            this.dialogVisible = false
            this.$router.push({
                path: '/investment/download'
            })
        }
    }
}
</script>

<style scoped lang="less"></style>
